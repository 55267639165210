import React, { useState, useRef, useEffect } from "react";
import { Box, Slider, Typography, IconButton } from "@mui/material";
import {
  PlayArrow,
  Pause,
  Replay10,
  Forward10,
  VolumeUp,
} from "@mui/icons-material";

const AudioPlayer = ({ trackTitle, audioSrc, setCurrentTrack, tracks }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [volume, setVolume] = useState(50); // Volumen inicial al 50%
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume / 100;
    }
  }, [volume]);

  useEffect(() => {
    if (audioSrc && audioRef.current) {
      audioRef.current.load();
      audioRef.current.play().catch((error) => {
        console.error("Error al reproducir el audio:", error);
      });
      setIsPlaying(true);
    }
  }, [audioSrc]);

  const togglePlayPause = () => {
    if (audioSrc) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } else {
      if (tracks.length > 0) {
        setCurrentTrack(tracks[0]);
      }
    }
  };

  const handleProgress = () => {
    setCurrentTime(audioRef.current.currentTime);
    setProgress(
      (audioRef.current.currentTime / audioRef.current.duration) * 100
    );
  };

  const handleSliderChange = (event, newValue) => {
    const newTime = (newValue / 100) * audioRef.current.duration;
    audioRef.current.currentTime = newTime;
    setProgress(newValue);
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 16,
        left: "50%",
        transform: "translateX(-50%)",
        width: "90%",
        maxWidth: 500,
        bgcolor: "rgba(255, 255, 255, 0.1)",
        color: "#fff",
        borderRadius: 3,
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.3)",
        paddingX: 2,
        paddingY: 1,
        zIndex: 1000,
        backdropFilter: "blur(10px)", // Efecto Glass
        border: "1px solid rgba(255, 255, 255, 0.2)",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 600, color: "#fff", textAlign: "left" }}
      >
        {trackTitle || "Sin reproducción"}
      </Typography>

      <Box sx={{ width: "100%" }}>
        <Slider
          value={progress}
          onChange={handleSliderChange}
          aria-label="Audio Progress"
          sx={{
            color: "#D4AF37",
            height: 6,
            "& .MuiSlider-thumb": {
              width: 12,
              height: 12,
              transition: "0.3s ease",
              "&:hover": {
                boxShadow: "0px 0px 0px 8px rgba(212, 175, 55, 0.16)",
              },
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            color: "#D4AF37",
            fontSize: 14,
            marginTop: -2,
          }}
        >
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration - currentTime)}</span>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          onClick={() => (audioRef.current.currentTime -= 10)}
          sx={{ color: "#D4AF37" }}
        >
          <Replay10 sx={{ fontSize: 36 }} />
        </IconButton>
        <IconButton onClick={togglePlayPause} sx={{ color: "#D4AF37", mx: 1 }}>
          {isPlaying ? (
            <Pause sx={{ fontSize: 48 }} />
          ) : (
            <PlayArrow sx={{ fontSize: 48 }} />
          )}
        </IconButton>
        <IconButton
          onClick={() => (audioRef.current.currentTime += 10)}
          sx={{ color: "#D4AF37" }}
        >
          <Forward10 sx={{ fontSize: 36 }} />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
        <VolumeUp sx={{ color: "#D4AF37", mr: 1 }} />
        <Slider
          value={volume}
          onChange={handleVolumeChange}
          aria-label="Volume"
          sx={{
            color: "#D4AF37",
            width: "100%",
            "& .MuiSlider-thumb": {
              width: 12,
              height: 12,
              transition: "0.3s ease",
              "&:hover": {
                boxShadow: "0px 0px 0px 8px rgba(212, 175, 55, 0.16)",
              },
            },
          }}
        />
      </Box>

      <audio
        ref={audioRef}
        src={audioSrc || ""}
        onTimeUpdate={handleProgress}
        onLoadedMetadata={() => setDuration(audioRef.current?.duration || 0)}
        onEnded={() => setIsPlaying(false)}
      />
    </Box>
  );
};

export default AudioPlayer;
