import {
  Container,
  Grid,
  Typography,
  useTheme,
  List,
  ListItem,
  IconButton,
} from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import Logo from "../assets/images/origen.svg";
import Uno from "../assets/images/origen/profile/1.png";
import Dos from "../assets/images/origen/profile/2.png";
import Tres from "../assets/images/origen/profile/3.png";
import Cuatro from "../assets/images/origen/profile/4.png";
import Seis from "../assets/images/origen/profile/6.png";
import Ocho from "../assets/images/origen/profile/8.png";
import AudioUNO from "../assets/audios/1.mp3";
import AudioDOS from "../assets/audios/2.mp3";
import AudioDOSUNO from "../assets/audios/2.1.mp3";
import AudioTRES from "../assets/audios/3.mp3";
import AudioTRESUNO from "../assets/audios/3.1.mp3";
import AudioTRESDOS from "../assets/audios/3.2.mp3";
import AudioTRESTRES from "../assets/audios/3.3.mp3";
import AudioTRESCUATRO from "../assets/audios/3.4.mp3";
import AudioTRESCINCO from "../assets/audios/3.5.mp3";
import AudioCUATRO from "../assets/audios/4.mp3";
import AudioCINCO from "../assets/audios/5.mp3";
import AudioSIETE from "../assets/audios/7.mp3";
import AudioOCHO from "../assets/audios/8.mp3";
import AudioPlayer from "../assets/components/AudioPlayer";
import VAUDIO1 from "../assets/audios/volumen2/01.mp3";
import VAUDIO2 from "../assets/audios/volumen2/02.mp3";
import VAUDIO3 from "../assets/audios/volumen2/03.mp3";
import VAUDIO4 from "../assets/audios/volumen2/04.mp3";
import VAUDIO5 from "../assets/audios/volumen2/05.mp3";
import VAUDIO6 from "../assets/audios/volumen2/06.mp3";
import VAUDIO7 from "../assets/audios/volumen2/07.mp3";
import VAUDIO8 from "../assets/audios/volumen2/08.mp3";
import VAUDIO9 from "../assets/audios/volumen2/09.mp3";
import VAUDIO10 from "../assets/audios/volumen2/10.mp3";
import VAUDIO11 from "../assets/audios/volumen2/11.mp3";
import VAUDIO12 from "../assets/audios/volumen2/12.mp3";
import VAUDIO13 from "../assets/audios/volumen2/13.mp3";
import VAUDIO14 from "../assets/audios/volumen2/14.mp3";
import VAUDIO15 from "../assets/audios/volumen2/15.mp3";
import VAUDIO16 from "../assets/audios/volumen2/16.mp3";
import VORIGEN1 from "../assets/images/origen/volumen2/01.png";
import VORIGEN2 from "../assets/images/origen/volumen2/02.png";
import VORIGEN3 from "../assets/images/origen/volumen2/03.png";
import VORIGEN4 from "../assets/images/origen/volumen2/04.png";
import VORIGEN5 from "../assets/images/origen/volumen2/05.png";
import VORIGEN6 from "../assets/images/origen/volumen2/06.png";
import VORIGEN7 from "../assets/images/origen/volumen2/07.png";
import VORIGEN8 from "../assets/images/origen/volumen2/08.png";
import VORIGEN9 from "../assets/images/origen/volumen2/09.png";
import VORIGEN10 from "../assets/images/origen/volumen2/10.png";
import VORIGEN11 from "../assets/images/origen/volumen2/11.png";
import VORIGEN12 from "../assets/images/origen/volumen2/12.png";
import VORIGEN13 from "../assets/images/origen/volumen2/13.png";
import VORIGEN14 from "../assets/images/origen/volumen2/14.png";
import VORIGEN15 from "../assets/images/origen/volumen2/15.png";
import VORIGEN16 from "../assets/images/origen/volumen2/16.png";

const Origen = () => {
  const theme = useTheme();
  const audioPlayerRef = useRef(null);
  const [currentTrack, setCurrentTrack] = useState({});

  const data = [
    { title: "Introducción", audioSrc: AudioUNO },
    {
      title: "Cuenta Cuentos",
      audioSrc: AudioDOS,
      subTracks: [{ title: "Cuento", audioSrc: AudioDOSUNO }],
      imageSrc: Uno,
    },
    {
      title: "Proceso del Chocolate",
      audioSrc: AudioTRES,
      subTracks: [
        { title: "Selección y corte", audioSrc: AudioTRESUNO },
        { title: "Desgranado", audioSrc: AudioTRESDOS },
        { title: "Lavado y secado", audioSrc: AudioTRESTRES },
        {
          title: "Tostado, descascarillado y molido",
          audioSrc: AudioTRESCUATRO,
        },
        { title: "Pasta", audioSrc: AudioTRESCINCO },
      ],
      imageSrc: Dos,
    },
    { title: "Ritual del Cacao", audioSrc: AudioCUATRO, imageSrc: Tres },
    { title: "El Mercado", audioSrc: AudioCINCO, imageSrc: Cuatro },
    { title: "La Molienda", audioSrc: AudioSIETE, imageSrc: Seis },
    { title: "Zona de Trueque", audioSrc: AudioOCHO, imageSrc: Ocho },
    {
      title: "Don Niqo González, Curandero chol",
      audioSrc: VAUDIO1,
      imageSrc: VORIGEN1,
    },
    {
      title: "El Cacao, el Ritual y el Desarrollo",
      audioSrc: VAUDIO2,
      imageSrc: VORIGEN2,
    },
    {
      title: "Don Florencio Sanchez, Hacienda cacaotera Chocolates Cacep",
      audioSrc: VAUDIO3,
      imageSrc: VORIGEN3,
    },
    {
      title: "El fuego y la noche",
      audioSrc: VAUDIO4,
      imageSrc: VORIGEN4,
    },
    {
      title: "Ordenando el mundo",
      audioSrc: VAUDIO5,
      imageSrc: VORIGEN5,
    },
    {
      title: "Dos mitad del mundo",
      audioSrc: VAUDIO6,
      imageSrc: VORIGEN6,
    },
    {
      title: "Soplando vida",
      audioSrc: VAUDIO7,
      imageSrc: VORIGEN7,
    },
    {
      title: "Moliendo",
      audioSrc: VAUDIO8,
      imageSrc: VORIGEN8,
    },
    {
      title: "Moliendo con metate",
      audioSrc: VAUDIO9,
      imageSrc: VORIGEN9,
    },
    {
      title: "Monos en un árbol de cacao en el jardín del Edén.",
      audioSrc: VAUDIO10,
      imageSrc: VORIGEN10,
    },
    {
      title: "Procesión por la fiesta de la Virgen de Cupilco.",
      audioSrc: VAUDIO11,
      imageSrc: VORIGEN11,
    },
    {
      title: "Moliendo el cacao al metate en su casa",
      audioSrc: VAUDIO12,
      imageSrc: VORIGEN12,
    },
    {
      title: "Virginia Pop y su sobrina.",
      audioSrc: VAUDIO13,
      imageSrc: VORIGEN13,
    },
    {
      title: "Yasmira Lizbeth Chen Cóc.",
      audioSrc: VAUDIO14,
      imageSrc: VORIGEN14,
    },
    {
      title: "Escultura de gobernante con tocado y adorno de cacao.",
      audioSrc: VAUDIO15,
      imageSrc: VORIGEN15,
    },
    {
      title: "Insertos en plantación cacaotera.",
      audioSrc: VAUDIO16,
      imageSrc: VORIGEN16,
    },
  ];

  useEffect(() => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.play();
    }
  }, [currentTrack]);

  return (
    <Container
      maxWidth={false}
      sx={{
        padding: "0 !important",
        margin: "0 !important",
      }}
    >
      <Grid container sx={{ backgroundColor: "#5e3730", paddingBottom: 10 }}>
        {/* Sticky Logo Section with animation */}
        <Grid
          item
          xs={12}
          textAlign="center"
          sx={{
            position: "sticky",
            top: 0,
            backgroundColor: "#5e3730",
            zIndex: 10,
            paddingBottom: 1,
          }}
        >
          <motion.img
            src={Logo}
            alt="Logo Origen"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            style={{
              width: "100%",
              maxWidth: 250,
              padding: 15,
            }}
          />
        </Grid>

        {data.map((track, index) => (
          <Grid
            item
            container
            xs={12}
            direction="column"
            alignItems="center"
            key={index}
            paddingTop={10}
          >
            {/* Section animation */}

            <Grid item>
              {track.imageSrc && (
                <img
                  src={track.imageSrc}
                  alt={track.title}
                  style={{ height: "100%", maxHeight: 220 }}
                />
              )}
            </Grid>
            <Grid item>
              <Typography
                fontSize={32}
                color="#cca053"
                padding={1}
                textAlign="center"
                fontWeight={600}
              >
                {track.title.toUpperCase()}
              </Typography>
            </Grid>

            <Grid
              item
              sx={{
                width: "100%",
                maxWidth: 400,
                paddingBottom: "200px",
                paddingX: 5,
              }}
            >
              <List>
                <ListItem
                  sx={{
                    justifyContent: "flex-start",
                    padding: "10px 0",
                    cursor: "pointer",
                    color:
                      currentTrack.title === track.title ? "#cca053" : "#fff",
                  }}
                  onClick={() => setCurrentTrack(track)}
                >
                  <IconButton sx={{ color: "#cca053", marginRight: 1 }}>
                    <VolumeUpIcon />
                  </IconButton>
                  <Typography variant="body1" fontSize={18} fontWeight={500}>
                    {track.title}
                  </Typography>
                </ListItem>

                {/* Subtracks */}
                {track.subTracks &&
                  track.subTracks.map((subTrack, subIndex) => (
                    <ListItem
                      key={subIndex}
                      sx={{
                        justifyContent: "flex-start",
                        padding: "5px 20px",
                        cursor: "pointer",
                        color:
                          currentTrack.title === subTrack.title
                            ? "#cca053"
                            : "#aaa",
                      }}
                      onClick={() => setCurrentTrack(subTrack)}
                    >
                      <IconButton sx={{ color: "#cca053", marginRight: 1 }}>
                        <VolumeUpIcon />
                      </IconButton>
                      <Typography
                        variant="body2"
                        fontSize={16}
                        fontWeight={500}
                      >
                        {subTrack.title}
                      </Typography>
                    </ListItem>
                  ))}

                {index < data.length - 1 && (
                  <div style={{ backgroundColor: "#895147", height: 0.5 }} />
                )}
              </List>
            </Grid>
          </Grid>
        ))}

        {/* Audio Player */}
        <AudioPlayer
          trackTitle={currentTrack.title}
          audioSrc={currentTrack.audioSrc}
          setCurrentTrack={setCurrentTrack}
          tracks={data}
        />
      </Grid>
    </Container>
  );
};

export default Origen;
